<template>
	<b-card class="earnings-card">
		<b-row>
			<b-col cols="12">
				<!-- chart -->
				<vue-apex-charts ref="chartCountReference" height="400" :options="chartOptions" :series="chartSeries" />
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	import {
		BCard,
		BRow,
		BCol,
		BCardTitle,
		BCardText
	} from "bootstrap-vue";
	import VueApexCharts from "vue-apexcharts";
	import moment from 'moment';
	import {
		$themeColors
	} from "@themeConfig";

	const $earningsStrokeColor2 = "#28c76f66";
	const $earningsStrokeColor3 = "#28c76f33";
	export default {
		components: {
			BCard,
			BRow,
			BCol,
			BCardTitle,
			BCardText,
			VueApexCharts,
		},
		props: {
			prop_statisticsFilter: {
				type: Object,
				default: () => { },
			}
		},
		watch: {
			prop_statisticsFilter(newVal) {
				this.selected_filters = newVal;
				this.fetchChartData();
			},
		},
		data() {
			return {
				chartOptions: {
					chart: {
                        toolbar: {
                            show: true,
                            tools: {
                                download:false
                            }
                        },
						id: "vuechart-example",
						noData: {
                            text: undefined,
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                color: undefined,
                                fontSize: '14px',
                                fontFamily: undefined
                            }
					    }
					},
					xaxis: {
						categories: [],
						labels: {
							minHeight: 100,
							maxHeight: 140,
							style: {
								fontSize: '12px',
								fontWeight: 400,
							},
						},
					},
				},
				chartSeries: [{
					name: "Chart Data",
					data: [],
				}],
				selected_filters: this.prop_statisticsFilter
			};
		},
		methods: {
			fetchChartData() {
				this.$summaryAPI.get('getChartDataAJAX', {
					"chart": this.selected_filters.statisticsItems.name,
					"siteId": this.$root.appSiteObject.siteId,
					"date_type": this.selected_filters.date_type,
					"customDateTypeFilter": this.selected_filters.customDateTypeFilter
				}).then((response) => {
					response = response.data;
					/* MAKE CHART ARRAY : START */
					let xaxisCategoriesArray = [];
                    if (response.data && response.xaxis_categories) {
						for (const xaxisItem of response.xaxis_categories) { /* FOR XAXIS */
							xaxisCategoriesArray.push(xaxisItem);
						}
					}
					/* MAKE CHART ARRAY : END */

					/* FOR UPDATE CHART DATA : START */
					this.$refs.chartCountReference.updateSeries([{
						data: response.data ?? [],
					}]);
					this.$refs.chartCountReference.updateOptions({
						xaxis: {
							categories: xaxisCategoriesArray
						}
					});
					/* FOR UPDATE CHART DATA : END */
				});
			},
		}
	};
</script>