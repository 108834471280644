<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col xl="12" md="12">
				<statistics @selectedFilter="statisticsFilter($event)"></statistics>
			</b-col>
		</b-row>

		<b-row class="match-height">
			<b-col lg="12">
				<b-row class="match-height">
					<b-col lg="12" md="12">
						<earnings-chart :prop_statisticsFilter="statisticsFilterData"></earnings-chart>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</section>
</template>

<script>
	import {
		BRow,
		BCol
	} from "bootstrap-vue";
	
	import Statistics from "./Statistics.vue";
	import EarningsChart from "./EarningsChart.vue";
	
	export default {
		components: {
			BRow,
			BCol,
			Statistics,
			EarningsChart,
		},
		data() {
			return {
				statisticsFilterData: {}
			};
		},
		methods: {
			statisticsFilter(dataObject) {
				this.statisticsFilterData = dataObject;
			}
		},
		created() { },
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
	@import "@core/scss/vue/libs/chart-apex.scss";
</style>