<template>
	<b-card no-body>
		<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
			<b-card-header>
				<b-card-title>Filters</b-card-title>
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col xl="2" sm="12">
						<Select2 v-model="dateTypeValue" :options="dateTypeOptions" @change="selectDateTypeChangeEvent($event)" />
					</b-col>
					<b-col xl="3" sm="12">
						<VueCtkDateTimePicker v-if="dateTypeValue == 'custom_date'" v-model="customDateTypeFilter" :overlay="true" :noShortcuts="true" :range="true" :onlyDate="true" :time="false" format="MM/DD/YYYY" formatted="ll" @is-hidden="changeCustomDateEvent" />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="border p-1 m-1" :class="statisticsItems[index].customClass" v-for="(item, index) in statisticsItems" :key="statisticsItems[index].icon" xl="2" sm="11">
						<b-media no-body @click="statisticItem(index);" style="cursor: pointer;">
							<b-media-aside class="mr-2">
								<b-avatar size="50" :variant="statisticsItems[index].color">
									<feather-icon size="30" :icon="statisticsItems[index].icon" />
								</b-avatar>
							</b-media-aside>
							<b-media-body class="my-auto">
								<h4 class="font-weight-bolder mb-0"> {{ statisticsItems[index].title }} </h4>
								<b-card-text class="font-small-3 mb-0"> {{ statisticsItems[index].subtitle }} </b-card-text>
							</b-media-body>
						</b-media>
					</b-col>
				</b-row>
			</b-card-body>
		</b-overlay>
	</b-card>
</template>
<script>
	import {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay
	} from 'bootstrap-vue';

	export default {
		components: {
			BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody, BLink, BOverlay
		},
		props: {
		},
		data() {
			return {
				isFetchingRecordsFromServer: false, /* FOR LOADER : DEFAULT OFF */
				selected_statistic_index: 'views',
				statisticsItems: {
					'views': {
						name: 'views',
						icon: "EyeIcon",
						color: "light-primary",
						title: 0,
						subtitle: "Views",
						customClass: "mb-2 btn-outline-primary active",
					},
					'calls': {
						name: 'calls',
						icon: "PhoneIcon",
						color: "light-info",
						title: 0,
						subtitle: "Calls",
						customClass: "mb-2",
					},
					'emails': {
						name: 'emails',
						icon: "MailIcon",
						color: "light-danger",
						title: 0,
						subtitle: "Emails Collected",
						customClass: "mb-2",
					},
					'clicks': {
						name: 'clicks',
						icon: "MousePointerIcon",
						color: "light-success",
						title: 0,
						subtitle: "Clicks",
						customClass: "mb-2",
					},
					'socials': {
						name: 'socials',
						icon: "Share2Icon",
						color: "light-warning",
						title: 0,
						subtitle: "Social Conversions",
						customClass: "mb-2",
					}
				},
				statisticsItems_views: '',
				statisticsItems_calls: '',
				statisticsItems_emails: '',
				statisticsItems_clicks: '',
				statisticsItems_socials: '',
				customDateTypeFilter: '',
				dateTypeValue: '',
				dateTypeOptions: [{
					id: 'today',
					text: 'Today'
				}, {
					id: 'yesterday',
					text: 'Yesterday'
				}, {
					id: 'last7days',
					text: 'Last 7 days'
				}, {
					id: 'lastweek',
					text: 'Last week'
				}, {
					id: 'thismonth',
					text: 'This month'
				}, {
					id: 'last30days',
					text: 'Last 30 days'
				}, {
					id: 'lastmonth',
					text: 'Last month'
				}, {
					id: 'last90days',
					text: 'Last 90 days'
				}, {
					id: 'custom_date',
					text: 'Custom Date'
				}, {
					id: 'all_time',
					text: 'All Time'
				}]
			}
		},
		watch: {
			statisticsItems_views: function (newVal, oldVal) {
				this.statisticsItems['views'].title = newVal;
			},
			statisticsItems_calls: function (newVal, oldVal) {
				this.statisticsItems['calls'].title = newVal;
			},
			statisticsItems_emails: function (newVal, oldVal) {
				this.statisticsItems['emails'].title = newVal;
			},
			statisticsItems_clicks: function (newVal, oldVal) {
				this.statisticsItems['clicks'].title = newVal;
			},
			statisticsItems_socials: function (newVal, oldVal) {
				this.statisticsItems['socials'].title = newVal;
			},
		},
		created() {
			this.getDefaultFilterElements(); /* FETCH FILTER FROM VUEX */
			this.fetchCurrentFilterRecords(); /* USE FOR FETCH DEFAULT DATE FILTER BASED ON PAGE SETTING */
			/**
			 * WHEN SITE CHANGE FROM HEADER SITE LIST : START
			 * CALLING FROM src\layouts\components\nav-bar-items\SiteDropdown.vue
			 */
			EventBus.$on('eventChangeAppSite', () => {
				this.fetchCurrentFilterRecords();
			});
			/**
			 * WHEN SITE CHANGE FROM HEADER SITE LIST : END
			 */
		},
		mounted() {
		},
		methods: {
			selectDateTypeChangeEvent(val) {
				this.dateTypeValue = val;
				/* TO CHECK CUSTOM DATE OR NOT : START */
				if (this.dateTypeValue == 'custom_date') {
					this.customDateTypeFilter = '';
				} else {
					this.fetchCurrentFilterRecords();
				}
				/* TO CHECK CUSTOM DATE OR NOT : END */

				/* STORE FILTER IN VUEX AND DB : START */
				this.$root.setDefaultFilterElements({
					page: 'siteSummary',
					filterObject: {
						'search_id': val
					},
				});
				/* STORE FILTER IN VUEX AND DB : END */

			},
			statisticItem(index) { /* SELECT STATISTIC ITEM VIEWS CALLS EMAILS COLLECTED CLICKS SOCIAL CONVERSIONS */
				if (!index) return false;
				this.selected_statistic_index = index;
				_(this.statisticsItems).each(x => x.customClass = '');
				if(this.selected_statistic_index == 'views') {
					this.statisticsItems[this.selected_statistic_index].customClass = "btn-outline-primary active";
				}
				if(this.selected_statistic_index == 'calls') {
					this.statisticsItems[this.selected_statistic_index].customClass = "btn-outline-info active";
				}
				if(this.selected_statistic_index == 'emails') {
					this.statisticsItems[this.selected_statistic_index].customClass = "btn-outline-danger active";
				}
				if(this.selected_statistic_index == 'clicks') {
					this.statisticsItems[this.selected_statistic_index].customClass = "btn-outline-success active";
				}
				if(this.selected_statistic_index == 'socials') {
					this.statisticsItems[this.selected_statistic_index].customClass = "btn-outline-warning active";
				}
				this.fetchCurrentFilterRecords();
			},
			changeCustomDateEvent() {
				if (this.customDateTypeFilter.start && this.customDateTypeFilter.end) {
					this.loadChart();
					this.fetchCountStatisticsItems();
				} else {
					this.customDateTypeFilter = '' /* IF ANY START OR END DATE IS EMPTY */
				}
			},
			fetchCurrentFilterRecords() {
				this.loadChart();
				this.fetchCountStatisticsItems();
			},
			loadChart() {
				this.$emit('selectedFilter', {
					'date_type': this.dateTypeValue,
					'customDateTypeFilter': this.customDateTypeFilter,
					'statisticsItems': this.statisticsItems[this.selected_statistic_index]
				});
			},
			fetchCountStatisticsItems() {
				this.isFetchingRecordsFromServer = true; /* FOR LOADER : SHOW */
				this.$summaryAPI.get('getSiteCountAJAX', {
					'siteid': this.$root.appSiteObject.siteId,
					'date_type': this.dateTypeValue,
					'customDateTypeFilter': this.customDateTypeFilter,
				}).then((response) => {
					response = response.data;
					this.statisticsItems_views = response.total_visitor;
					this.statisticsItems_calls = response.total_call;
					this.statisticsItems_emails = response.total_email;
					this.statisticsItems_clicks = response.total_click;
					this.statisticsItems_socials = response.total_share;
					this.isFetchingRecordsFromServer = false; /* FOR LOADER : HIDE */
				});
			},

			/**
			 * GET FILTER FROM VUEX AND SET INTO ELEMENTS
			 */
			getDefaultFilterElements() {
				let searchIDFilter = this.$store.getters['appModuleFilters/getFilter']({
					page: 'siteSummary',
					filterKey: 'search_id',
				}) ?? 'today';
				/* FETCH FILTER FROM VUEX : END */
				this.dateTypeValue = searchIDFilter
			}
		},
	}
</script>